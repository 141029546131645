export const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const fullWeekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthDays = [
  ...Array.from({ length: 28 }, (_, index) => (index + 1).toString()),
  "last",
];

export const weekNumbers = ["1", "2", "3", "4", "last"];

const TARGET_GROUP_FIELD = "Target Group";
const STATUS_COLUMN_FIELD = "Status Column";
const STATUS_VALUE_FIELD = "Status Value";
const DUE_DATE_COLUMN_FIELD = "Due Date Column";

const BASE_API_URL = "/api/";
const SCHEDULE_API_URL = BASE_API_URL + "schedule/";

const PLAN_NAMES = {
  v1_10: "Basic",
  v1_25: "Standard",
  v1_50: "Professional",
  v1_100: "Premium",
  v1_250: "Advanced",
  v1_500: "Business",
  v1_1000: "Enterprise",
  v2_5: "Starter",
  v2_10: "Basic",
  v2_25: "Standard",
  v2_50: "Professional",
  v2_100: "Premium",
  v2_250: "Advanced",
  v2_500: "Business",
  v2_1000: "Enterprise",
  v3_free: "Free",
  v3_10: "Basic",
  v3_25: "Standard",
  v3_50: "Professional",
  v3_100: "Premium",
  v3_250: "Advanced",
  v3_500: "Business",
  v3_1000: "Enterprise",
  v4_free: "Free",
  v4_10: "Limited Time Offer",
  v5_free: "Free",
  v5_10: "Basic",
  v5_25: "Standard",
  v5_50: "Professional",
  v5_100: "Enterprise",
  v6_free: "Free",
  v6_10: "Limited Time Offer",
  v7_free: "Free",
  v7_20: "Limited Time Offer",
};

// Map of special features to accounts for which that feature is enabled.
const SPECIAL_FEATURES = {
  useLatestItemOption: ["21034321", "23985142"],
};

export {
  TARGET_GROUP_FIELD,
  STATUS_COLUMN_FIELD,
  STATUS_VALUE_FIELD,
  DUE_DATE_COLUMN_FIELD,
  BASE_API_URL,
  SCHEDULE_API_URL,
  PLAN_NAMES,
  SPECIAL_FEATURES,
};
