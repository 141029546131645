import { useContext } from "react";
import { Flex, Text, Checkbox, Dropdown } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { SPECIAL_FEATURES } from "../../utils/constants";

const DuplicateItemOption = () => {
  const {
    accountId,
    isSubitem,
    duplicateItem,
    setDuplicateItem,
    withUpdates,
    setWithUpdates,
    useLatestItem,
    setUseLatestItem,
    areFieldsDisabled,
  } = useContext(ScheduleContext);

  const withUpdatesOptions = [
    { value: 0, label: "Without updates" },
    { value: 1, label: "With updates" },
  ];

  const useLatestItemOptions = [
    { value: 0, label: "Original" },
    { value: 1, label: "Latest" },
  ];

  const textElement = (text) => (
    <Text type="text1" color={duplicateItem ? "primary" : "secondary"}>
      {text}
    </Text>
  );

  const onFieldToggle = (e) => {
    setDuplicateItem(e.target.checked);
    if (!e.target.checked) {
      setWithUpdates(false);
      setUseLatestItem(false);
    }
  };

  const onUseLatestItemOptionChange = (option) => {
    setUseLatestItem(!!option.value);
  };

  const onWithUpdatesOptionChange = (option) => {
    setWithUpdates(!!option.value);
  };

  return (
    <Flex direction="Column" gap={15} align="Start">
      <Flex gap={10}>
        <Checkbox
          labelClassName="checkbox-label"
          checked={duplicateItem}
          onChange={onFieldToggle}
          disabled={areFieldsDisabled}
        />

        {!SPECIAL_FEATURES["useLatestItemOption"].includes(accountId) ? (
          textElement("Duplicate this item")
        ) : (
          <Flex gap={10}>
            {textElement("Duplicate")}

            <div className="use-latest-item">
              <Dropdown
                options={useLatestItemOptions}
                size="small"
                value={useLatestItemOptions[+useLatestItem]}
                onChange={onUseLatestItemOptionChange}
                disabled={areFieldsDisabled}
                clearable={false}
              />
            </div>

            {textElement("Item")}
          </Flex>
        )}

        {duplicateItem && (
          <div className="with-updates">
            <Dropdown
              options={withUpdatesOptions}
              size="small"
              value={withUpdatesOptions[+withUpdates]}
              onChange={onWithUpdatesOptionChange}
              disabled={areFieldsDisabled}
              clearable={false}
            />
          </div>
        )}
      </Flex>

      {duplicateItem && (
        <Text type="text1" style={{ paddingLeft: "25px" }}>
          {">"} Following fields will be set on duplicated {isSubitem ? "subitem" : "item"}
        </Text>
      )}
    </Flex>
  );
};

export default DuplicateItemOption;
